section[name="logo"] {
    margin-top: calc(var(--site-navigation-height) + 50px + 40px);
    
    svg {
        fill: white;
        height: 120px;
        max-width: 90%;
        width: auto;
        filter: drop-shadow(0 0 76px #ffffff96) drop-shadow(0 0 86px #ffffff96);
    }
}
