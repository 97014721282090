games-scroller {
    height: 100px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: fit-content;

    
    @media screen and (min-height: 1200px) {
        margin-top: 100px;
    }

    @media screen and (min-height: 1000px) and (max-height: 1200px) {
        margin-top: 30px;
    }


    > a {
        display: contents;
        

        &:hover {
            .text {
                transform: scale(1.1);
                
            }
        }
    }

    .games-scroller {
        // display: flex;
        // align-items: center;
        // height: 100%;
        // white-space: nowrap;
        // overflow-x: auto;
        // scrollbar-width: none; /* Hide scrollbar in Firefox */
        // -ms-overflow-style: none; /* Hide scrollbar in IE 10+ */

        //display: flex; /* Ensures images are laid out in a row */
        position: relative;
        white-space: nowrap; /* Prevent wrapping */
        will-change: transform; /* Optimize GPU rendering */
        transition: transform 0.016s linear; /* Smooth transitions (60 FPS) */
    }

    .games-scroller::-webkit-scrollbar {
        display: none; /* Hide scrollbar in Chrome/Safari */
    }

    img {
        height: 100px;
        width: 220px;
        margin: 0 10px;
        cursor: pointer;
    }

    .text {
        position: absolute;
        height: 100%;
        top: 0;
        display: flex;
        background: linear-gradient(to right, black, #0000005e, #0000005e, #0000005e, black);
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 26px;
        text-shadow: 0px 0px 6px black;
        color: white;
        transition: all .5s;
    }
}
