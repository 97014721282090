html {
    scroll-behavior: smooth;
    height: fit-content;
}

body[name=front] {

}

section, feature-matrix, social-links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    
    header {
        font-size: 35px;
        text-align: center;
        text-shadow: 0 0 40px black;
        margin: 80px 0 40px;
        background: -webkit-linear-gradient(#ffffff, #995400, #422900);
        background: linear-gradient(#ffffff, #995400, #422900);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent; 
        font-family: sans-serif; 
        border-bottom: 1px solid #5b3500;
        width: 700px;
        max-width: 100%;
        padding-bottom: 10px;
    }

    p {
        font-family: sans-serif;
        color: #ffffffed; //#ffffffbd;
        text-align: center;
        padding: 0 5px;
    }

    b {
        color: var(--text-color);
    }
}

section[name="rooms"] {
    z-index: 2;
    margin-top: -300px;

    @media screen and (max-width: 600px) {
        margin-top: -240px;
    }

    // header {
    //     text-shadow: 0 0 40px black, 0 0 60px black, 0 0 80px black, 0 0 100px black;
    // }

    .rooms {
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    .room {
        flex: 0 1 350px;
        line-height: 1.2;
        
        &[coming-soon] {
            pointer-events: none;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }

        @media screen and (max-width:800px) {
            flex: 0 1 200px;
        }
    }

    .description {
        min-height: 30px;
        height: fit-content;
    }

    .price {
        height: 4px;
        font-size: 18px;
        font-weight: bold;

        span {
            color: white;
        }
    }

    .play-time {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;

        svg {
            display: inline;
            position: relative;
            width: 18px;
            fill: white;
            height: 18px;
            stroke: white;
            margin-right: 7px;
        }
    }

    h2 {
        font-family: sans-serif; 
        margin: 40px 0 0;
        font-size: 20px;
        color: white;
        text-align: center;
    }

    a {
        display: flex;
        text-decoration: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 20px;
    }

    img { 
        max-width: 360px;
        width: 100%;
        margin: 0 10px;
    }

    button {
        font-size: 20px;
        border-radius: 50px;
        padding: 14px 20px;
        background: hsl(34, 48.03%, 90.13%) linear-gradient(hsl(51.16, 100%, 43%) 5%, hsl(34, 96%, 36%) 100%);
        border: none;
        font-weight: bold;
        text-shadow: 0px 1px 0px #ffffff52;
        box-shadow: 0 0 30px #d09041;
        margin: 10px 0;
        cursor: pointer;
        max-width: 250px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-indent: 10px;
        white-space: nowrap;

        @media screen and (max-width:800px) {
            font-size: 14px;
            text-indent: 0;
        }

        svg {
            position: relative !important;
            height: 29px;
            width: auto;
            margin-right: 0px;
            margin-left: 10px;
            margin-top: -9px;
            margin-bottom: -10px;

            @media screen and (max-width:800px) {
                display: none;
            }
        }
    }
}

section[name="notify-me"] {
    > div {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: center;
        max-width: 1200px;
        width: 100%;

        > div {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            padding-bottom: 10px;
           

            a {
                padding: 10px;
            }
        }

        > img {
            max-width: 500px;
            width: 100%;
            height: auto;
            margin-right: 50px;

            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }

    button {
        cursor: pointer;
        font-size: 20px;
        border-radius: 6px;
        padding: 5px 10px;
        text-align: center;
        background-color: #ccc;
        transition: all 0.3s ease-in-out;
        border: none;
        margin-top: 30px;
        width: 130px;

        box-shadow: 0 0 20px #e28405;

        &:focus {
            background-color: white;
            outline: none;
        }
    }
}

section[name="attribution"] {
    font-family: sans-serif;
    font-size: 10px;
    flex-direction: row;

    a {
        font-family: sans-serif;
        font-size: 10px;
    }

    .attribution {
        margin: 0 10px;
    }
}

// section[name="features"] {
//     > div {
//         display: grid;
//         // grid-template-columns: 1fr 1fr 1fr;
//         grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//         grid-gap: 20px;
//         width: 80%;
//         max-width: 1280px;
//         margin: 0 auto;
//     }

//     .feature {
//         display: flex;
//         font-family: sans-serif;
//         flex-direction: column;

//         > div:first-of-type {
//             display: flex;
//             align-items: center;
//             color: white;
//             margin-top: 14px;
//             flex: 0 1 24px;
//         }

//         > div {
//             color: #ffffffbd;
//             margin-bottom: 16px;
//         }

//         svg {
//             max-width: 45px;
//             max-height: 45px;
//             margin-right: 16px;

//             flex: 0 0 75px;
//             fill: var(--text-color);
//             stroke: var(--text-color);
//         }
//     }
// }

footer {
    border-top: 1px solid #ffffff2e;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;
    font-family: sans-serif;
    font-size: 12px;
    margin-top: 100px;

    .legal {
        display: flex;
        margin-bottom: 20px;

        a {
            padding: 10px;
            display: block;
            font-size: 14px;
        }
    }

    .attribution {
        font-weight: 100;
        text-align: center;
    }
}
