:root {
    --footer-height: 300px;
}

site-footer {
    margin-top: 20px;
    width: 100%;
    height: calc(var(--footer-height) - 20px);
    flex: 0 0 calc(var(--footer-height) - 20px);
    background: linear-gradient(180deg, #080808, #131313);
    font-family: sans-serif;
    display: flex;
    justify-content: space-around;
    padding: 20px;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid #2b2b2b;

    @media screen and (max-width: 800px) {
        padding-top: 5px;
    }

    top: calc(100% - 300px);
    // position: relative;

    .links {
        color: #909090;
        // margin-top: 60px;
        text-align: center;

        a {
           color: #909090;
            text-decoration: underline;
            text-underline-position: under;
            @media screen and (max-width: 800px) { 
                font-size: 12px;
            }
        }
    }

    .copyright{
        color: #727272;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        svg {
            height: 18px;
            margin-left: 10px;
        }
    }

    .tag-line {
        font-size: 20px;
        // color: #ffffffbd;
        text-align: center;
        padding: 0 5px;
        width: 60%;
        line-height: 1.3;


        background: linear-gradient(#ffffff, #909090);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 14px;
        }
    }
}
