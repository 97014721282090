order-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    
    p {
        font-weight: bold;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed !important;

        loader-button {
            pointer-events: none;
            cursor: not-allowed !important;
        }
    }

    &[completed] {
        loader-button {
            display: none;
        }
    }
}