giftcard-details {
    display: flex;
    align-items: center;
    flex-direction: column;
   
   
    
    width: 100%;
    .details {
        display: flex;
        flex-direction: column;
        max-width: 1200px !important;
        border-radius: var(--border-radius) !important;
        padding: 0 !important;
        position: relative;
        
    }

    [name=counter] {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 14px;
    }


    textarea {
        font-family: serif;
        font-size: 20px;
        width: 100%;
        height: 100%;
        // field-sizing: content;
        border: none;
        border-radius: 10px;
        padding: 30px;
        min-height: 150px;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 12px 1px #2200ff;
        }
    }

    input[type="text"],
    input[type="email"] {
        text-align: center;
        padding: 10px;
        border: none;
        border-radius: 10px;
        box-shadow: inset 1px 1px 5px 2px #ccc;
        margin: 20px;
        // text-transform: uppercase;
        max-width: 100%;
        -webkit-appearance: none;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 12px 1px #2200ff;
        }
    }

    &[completed] {
        pointer-events: none;
    }
}