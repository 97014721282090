giftcard-preview {
    flex: 1 0 40%;
    height: 200px;
    border-radius: var(--border-radius) !important;
    background: white !important;
    
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: serif;
    width: 100%;
    height: 100%;


    padding: 0 !important;
    height: 650px; // temp
    max-width: 460px !important;

    &[hidden] {
        display: none;
    }

    * {
        user-select: none;
    }

    svg {
        width: auto;
        height: 100%;
        max-width: 460px;
        max-height: 650px;
        border-radius: var(--border-radius) !important;
    }

    
    .loader {
        svg {
            fill: #9e8925;
            width: 180px;
            height: 180px;
        }
        display: none;
    }

    &[loading] {
        .loader {
            display: block;
        }
    }

    @media screen and (max-width: 1024px) {
        max-width: 100% !important;
        height: auto;
    }
}