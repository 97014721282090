game-gallery {
    max-width: 100%;
    width: 835px;
    display: block;
    border-radius: 3px;

    > img {
        width: 100%;
        border: 2px solid #272727;
        max-height: 466px;
        object-fit: contain;
    }

    [name=selector] {
        overflow-y: hidden;
        overflow-x: auto;
        border-radius: 3px;
        scroll-snap-type: x mandatory;
        position: relative;
        scrollbar-width: thin;
        scrollbar-color: #686868 #000;
        display: flex;
        padding-top: 15px;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 6px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.545); //rgba(0,0,0,.5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        button {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0 5px 0 0;
            box-shadow: 0 0 1px #b3b3b3; 
            scroll-snap-align: start;
            border: 2px solid black;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
            
            &[selected] {
                border: 2px solid #d5d5d5;
                position: relative;

                pointer-events: none;

                &:after {
                    bottom: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(213, 213, 213, 0);
                    border-bottom-color: #d5d5d5;
                    border-width: 15px;
                    margin-left: -15px;
                }
            }

            img {
                height: 100px;
            }
        }

       
    }
}