quick-info {
    --info-color: #c9c9c9;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-family: sans-serif;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        
    }

    .info {
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: center;

        .title {
            margin-bottom: 10px;
            // font-weight: bold;
            font-size: 15px;
            color: var(--info-color);
            width: 190px;
            padding-bottom: 4px;
            text-align: center;
            border-bottom: 1px solid var(--info-color);
        }

        .data {
            font-size: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--info-color);
        }
    }

    svg {
        stroke: var(--info-color);
        fill: var(--info-color);
        width: 26px;
        margin-right: 8px;

       
    }

    [name="difficulty"] {
        svg {
            rect {
                fill: none;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        [difficulty="1"] {
            svg {
                &:nth-child(1) {
                    rect {
                        fill: var(--info-color) !important;
                    }
                }
            }
        }

        [difficulty="2"] {
            svg {
                &:nth-child(1), &:nth-child(2) {
                    rect {
                        fill: var(--info-color) !important;
                    }
                }
            }
        }

        [difficulty="3"] {
            svg {
                &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                    rect {
                        fill: var(--info-color) !important;
                    }
                }
            }
        }

        [difficulty="4"] {
            svg {
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    rect {
                        fill: var(--info-color) !important;
                    }
                }
            }
        }

        [difficulty="5"] {
            svg {
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    rect {
                        fill: var(--info-color) !important;
                    }
                }
            }
        }
    }
}
