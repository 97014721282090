flip-button {
    box-shadow: 0 0 40px 0 #ccc;
    border-radius: 30px;
    margin-top: 12px;
    margin-bottom: 40px;

    @media screen and (max-width: 450px) {
        padding: 10px;
        width: 90%;
    }


    button {
        width: 200px;

        @media screen and (max-width: 450px) {
            width: 100%;
        }
    
        margin: 0;
        cursor: pointer;
        color: var(--primary);
        background: none;

        &[active] {
            color: white;
            background: var(--primary);
        }
    }

    &[completed] {
        display: none;
    }
}