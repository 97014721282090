form-group {
    font-size: 20px;
    font-family: sans-serif;
    max-width: 350px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    label {
        margin: 30px 0px 10px;
        text-align: center;
    }

    input {
        font-size: 20px;
        border-radius: 6px;
        padding: 5px 10px;
        text-align: center;
        background-color: #ccc;
        transition: all 0.3s ease-in-out;
        border: none;

        &:focus {
            background-color: white;    
            outline: none;
            box-shadow: 0 0 20px #e28405;
        }
    }
}