stripe-payment {
    position: fixed;
    background-color: black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 100%;
        //height: 100%;
    }
}