social-links {
    

    > div {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: block;
            margin: 10px;

            svg {
                fill: white;
                width: 30px;
                height: 30px;
            }

            &:hover {
                svg {
                    fill: var(--text-color);
                }
            }
        }
        
    }
}