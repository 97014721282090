body[name=content] {
    height: 100%;
    margin-top: 70px;
    justify-content: flex-start;
    .content {
        max-width: 800px;
       
        
        font-size: 17px;
        font-family: sans-serif;
        padding: 0 10px;
        flex: 1;
        margin-bottom: auto;
        line-height: 1.2;
        
        h1, h2 {
            font-weight: 100;
            text-align: left;
            margin-top: 50px;
        }

        a {
            text-decoration: underline;
            text-decoration-skip-ink: auto;
            text-underline-offset: 2px;
        }

        // h1 {
        //     margin-top: 330px;
        // }

        // h2 {
        // }
    }
}