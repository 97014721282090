section[name="logo"] {
    margin-top: calc(var(--site-navigation-height) + 50px + 40px);

    svg {
        fill: white;
        height: 100px;
    }
}



section[name="gallery"] {
    
}
