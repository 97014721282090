notify-me {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    &[busy] {
        cursor: wait;
        pointer-events: none;

        input, button {
            opacity: .5;
        }
    }

    .error-text {
        padding: 10px;
        color: red;
        font-weight: 100;
        font-size: 16px;    
    }
}