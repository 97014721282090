:root {
    --site-navigation-height: 140px;

    @media screen and (max-width: 800px) {
        --site-navigation-height: 120px;
    }
}

site-navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    transition: all 0.5s;
    height: 140px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    align-items: center;

    nav {
        font-size: 18px;
        position: absolute;
        display: flex;
        display: flex;
        width: 100%;
        max-width: 700px;
        justify-content: space-evenly;
        align-items: center;
        height: var(--site-navigation-height);

        @media screen and (max-width: 800px) {
            font-size: 15px;
            top: 0;
        }

        a {
            display: flex;
            white-space: nowrap;
            justify-content: center;
            width: 186px;
            // text-shadow: 0 0 40px black;
            text-shadow: 0 1px rgb(67, 67, 67);
            color: white;

            &:hover {
                transition: all 0.5s ease-out;
                transform: scale(1.15);
            }

            &:last-of-type{
                @media screen and (max-width: 800px) {
                    opacity: 0;
                }
            }

            &:nth-child(4) {
                @media screen and (max-width: 420px) {
                    opacity: 0;
                }
            }
        }

        svg {
            height: 100px;
            filter: drop-shadow(0px 0px 40px black);
        }

        span[name="big"] {
            opacity: 1;
        }

        span[name="small"] {
            position: absolute;
            opacity: 0;
            

            svg {
                height: 46px;
                margin-top: 29px;
            }
        }
    }

    &[small] {
        // background-color: rgba(0, 0, 0, .5);
        background-color: rgb(100 100 100 / 50%);
        //background-color: #1414157f;
        position: fixed;
        top: 0  !important;;
        left: 0;
        height: 60px !important;
        z-index: 10;
        box-shadow: 0 0 10px black;

        a.logo {
            width: 40px;
        }

        nav {
            height: 100%  !important;
            top: 0  !important;;

            span[name="big"] {
                opacity: 0;
            }

            span[name="small"] {
                opacity: 1;
            }

           
        }

        
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backdrop-filter: saturate(180%) blur(5px);
            backdrop-filter: saturate(100%) blur(5px);
            backface-visibility: hidden;
            perspective: 1000;
            z-index: -1;
            // top: -1px;
            display: block;
            background-color: #00000082;
            backface-visibility: hidden;
        }
    }
}


body[name=front] {
    site-navigation {
        height: auto;
        nav {
            top: 50px;
        }
    }
}