order-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .details {
        display: flex;
        flex-direction: column;
        max-width: 1200px !important;
        border-radius: var(--border-radius) !important;
        
    }

    input[type="text"],
    input[type="email"] {
        text-align: center;
        padding: 10px;
        border: none;
        border-radius: 50px;
        box-shadow: inset 1px 1px 5px 2px #ccc;
        margin: 20px;
        // text-transform: uppercase;
        width: 100%;
        max-width: 350px;
        -webkit-appearance: none;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 12px 1px #2200ff;
        }
    }

    fieldset {
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;

        button[name="change-email"] {
            border: none;
            text-decoration: underline;
            color: #919191;
            background: none;
            cursor: pointer;
        }

        span {
            font-weight: 600;
        }
    }

    &[completed] {
        
        button {
            display: none !important;
        }
    }
}