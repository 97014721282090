status-panel {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - var(--footer-height) - var(--site-navigation-height));
    max-width: 600px;
    font-size: 24px;
    padding: 60px;

    @media screen and (max-width: 800px) {
        padding: 30px;    
    }

    &[busy] {
        .status {
            opacity: 0.5;
        }
    }

    header {
        font-size: 35px;
        text-align: center;
        margin: 40px 0 40px;
        background: -webkit-linear-gradient(#ffffff, #995400, #422900);
        background: linear-gradient(#626262, #995400, #422900);
          background-clip: border-box;
        background-clip: border-box;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: sans-serif;
        border-bottom: 1px solid #5b3500;
        width: 700px;
        max-width: 100%;
        padding-bottom: 10px;
    }

    .game {
        width: 100%;
        max-width: 800px;
        padding: 15px;
        display: flex;
        justify-content: space-between;

        &[status="Healthy"] {
            .status {
                svg {
                    fill: green;
                }
            }
        }

        &[status="Unhealthy"] {
            .status {
                svg {
                    fill: #c00;
                }
            }
        }

        &[status="Unknown"] {
            .status {
                svg {
                    fill: #df7e00;
                }
            }
        }
    }
}