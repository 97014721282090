section[name="order"] {
    font-size: 20px;

    p {
        max-width: 90%;
    }

    .order-button {
        font-size: 20px;
        border-radius: 50px;
        padding: 15px 30px;
        background: hsl(34deg 48.03% 90.13%)
            linear-gradient(hsl(51.16deg 100% 43%) 5%, hsl(34 96% 36% / 1) 100%);
        border: none;
        font-weight: bold;
        text-shadow: 0px 1px 0px #ffffff52;
        box-shadow: 0 0 30px #d09041;
        margin: 30px 0;
        cursor: pointer;

        &:hover {
            background: linear-gradient(
                hsl(34 96% 36% / 1) 0%,
                hsl(51.16deg 100% 43%) 100%,
                hsl(34deg 48.03% 90.13%) 100%
            );
        }
    }
}




section[name="description"] {
    font-family: sans-serif;
    font-size: 27px;
    width: 1000px;
    text-align: center;
    max-width: 100%;
    padding: 0 10px;

    p {
        font-family: serif;
    }
}


section[name="video"] {
    video {
        max-height: 650px;
        max-width: 90%;
        // height: auto;
        height: 100%;

        border: 1px solid #373737;
        border-radius: 3px;
    }

    p, a {
        color: #6b6b6b;
        text-decoration: none;
        font-size: 12px;
    }
}

p {
    a {
        text-decoration: underline;
        text-decoration-skip-ink: auto;
        text-underline-offset: 3px;
    }
}