checkout-control {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;

    @media screen and (max-width: 800px) {
        padding: 10px;
        
    }

    header {
        font-family: sans-serif;
        font-weight: 400;
        font-size: 28px;
        margin-bottom: 40px;
    }

    &[mode=self] {
        .vertical-spacer {
            display: none;
        }
    }

    

    div.columns {
        display: flex;
        flex-direction: row;
        max-width: 1024px;
        justify-content: center;
        align-items: center;
        width: 100%;

        .vertical-spacer {
            flex: 0 0 1px;
        }

        > div {
            display: none;
            // width: 100%;
            flex: 1 0 45%;
            max-width: 1024px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 19px;
            color: #545454;
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
    }

    

    .box {
        background: white;

        &:last-of-type {
            margin-bottom: 30px;
        }
    }

    h1 {
        width: 100%;
        max-width: 1024px;
        padding-left: 10px;
        font-size: 24px;
        font-weight: 300;
        margin-block-start: 0;
        margin-block-end: 18px;
        text-align: left;
        margin-top: 30px;

        @media screen and (max-width: 800px) {
            font-size: 20px;
        }

        // &:first-child {
        //     margin-top: 0;
        // }
    }

    h2 {
        font-size: 17px;
        font-weight: bold;
        text-align: center;
    }

    label {
        font-size: 17px;
        font-weight: bold;

        &:first-of-type {
            margin-top: 30px;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        max-width: 1200px !important;
        border-radius: 10px !important;
    }

    input[type="text"],
    input[type="email"] {
        text-align: center;
        padding: 10px;
        border: none;
        border-radius: 50px;
        box-shadow: inset 1px 1px 5px 2px #ccc;
        margin: 20px;
        // text-transform: uppercase;
        max-width: 100%;
        -webkit-appearance: none;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 12px 1px #2200ff;
        }
    }

    // input[type="text"] {
    //     font-size: 32px;
    //     letter-spacing: 3px;
    //     width: auto;
    // }

    input[type="email"] {
        font-size: 20px;
        text-transform: lowercase;
        // width: 350px;
    }

    button,
    button {
        background: var(--primary);
        border: none;
        color: white;
        font-weight: 500;
        padding: 10px 20px;
        border-radius: 30px;
        font-size: 16px; 
        display: inline-block;
        margin-bottom: 10px;
    }
}

