share-content {
    position: absolute;
    right: 30px;

    @media screen and (min-width: 910px) {
        right: var(--right-offset-hint, 30px);
    }

    button {
        color: white;
        cursor: pointer;
    }

    > button {
        background-color: #0071e3;
        
        border: none;
        border-radius: 9999px;
        font-size: 15px;
        height: 36px;
        width: 92px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 10px;

        &:hover {
            background-color: #0059b3;
        }
    }

    .options {
        display: none;
        position: absolute;
        // background-color: #303030;
        background-color: #303030; 
        border: none;
        border-radius: 10px;
        margin-top: 10px;
        width: 190px;
        right: 0px;
        padding: 10px 0;
        border: 1px solid  #616161;
        
        button {
            border: none;
            background: none;
            color: white;
            height: 38px;
            padding: 0 13px;
            font-size: 15px;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;

            &.device {
                border-bottom: 1px solid  #616161;
            }

            div:first-of-type {
                width: 20px;
                margin-right: 8px;
                display: flex;
                
                svg {
                    height: 20px;
                }
            }

            &:hover {
                background-color: #616161;
            }
        }

        &[show] {
            display: block;
        }
    };
}
