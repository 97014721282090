:root {
    --loader-button-background: #000;
    --loader-button-color: #fff;
    --loader-button-shadow: none;
}

loader-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 40px;

    > button, .shadow {
        background-color: var(--loader-button-background);
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: none;
        color: var(--loader-button-color);
        cursor: pointer;
        display: flex;
        font-size: 18px;
        height: 100%;
        transition: all 2s;
        width: 100%;
        margin: 0 !important;
        white-space: nowrap;
        box-shadow: var(--loader-button-shadow);
    }

    .shadow {
        position: absolute;
        top: 0;
        opacity: 0;
    }

    &[busy] {
        pointer-events: none;
    }
}