hero-player {
    position: relative;
    height: 100vh;
    min-height: 630px;
    width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: column;
    -webkit-box-align: center;
    align-items: center;

    > div {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        -webkit-mask-image: linear-gradient(
            transparent,
            rgb(255, 255, 255) 20%,
            rgb(255, 255, 255) 80%,
            transparent
        );
        mask-image: linear-gradient(
            transparent,
            rgb(255, 255, 255) 20%,
            rgb(255, 255, 255) 80%,
            transparent
        );

        > hero-viewer {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    video {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        aspect-ratio: 1280 / 720; 
    }

    blockquote {
        will-change: opacity;
        pointer-events: none;
        text-align: center;
        z-index: 1;
        font-family: serif;
        max-width: 90%;
        font-size: 17px;
        color: #d7d7d7;
        text-shadow: 0 1px rgb(67, 67, 67);
        //text-shadow: 0 0 40px black, 0 0 60px black, 0 0 80px black, 0 0 100px black;
        line-height: 1.3;
        // text-stroke: 1px rgb(44, 44, 44);
        // -webkit-text-stroke: 1px rgb(44, 44, 44);
        opacity: 1;
        transition: opacity 3s ease-in-out;

        @media screen and (min-width: 768px) {
            font-size: 30px;
        }

        @media screen and (min-width: 768px) {
            font-size: 30px;
        }

        @media screen and (min-width: 3000px) {
            font-size: 34px;
        }
        
        &[hide] {
            opacity: 0;
        }

        cite {
            font-size: 18px;
            font-style: normal;
            color: #c1c1c1;

            @media screen and (min-width: 768px) {
                font-size: 20px;
            }
    
            @media screen and (min-width: 3000px) {
                font-size: 24px;
            }
        }

        p {
            margin-top: 6px;
            margin-bottom: 10px;
        }
    }
}