
body[name="status"] {

    site-navigation {
        background-color: black;
    }

    header {
        font-size: 35px;
        text-align: center;
        text-shadow: 0 0 40px black;
        //margin: 80px 0 40px;
        background: -webkit-linear-gradient(#ffffff, #995400, #422900);
        background: linear-gradient(#ffffff, #995400, #422900);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: sans-serif;
        border-bottom: 1px solid #5b3500;
        width: 700px;
        max-width: 100%;
        padding-bottom: 10px;
    }

    // background-color: #f7f7f7;
    // background: linear-gradient(
    //     180deg,
    //     #ffffff,
    //     #fdfdfd,
    //     #fbfbfb,
    //     #f9f9f9,
    //     #f6f6f6,
    //     #f4f4f4,
    //     #f2f2f2,
    //     #f0f0f0
    // );
    color: white;
    font-family: sans-serif;
    min-height: 100vh;

    overflow-x: hidden;
    overflow-y: auto;

    justify-content: space-between;

    * {
        box-sizing: border-box;
    }
}