body[name=articles] {
    // max-width: 1280px;
    

    .articles {
        p a {
            display: block;
            margin-top: 10px;
        }
    }
}

article {
    font-family: sans-serif;
    line-height: 1.2;
    font-size: 16px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;

    header {
        
        > a, h1 {
            font-size: 35px;
            background: -webkit-linear-gradient(#ffffff, #995400, #422900);
            background: linear-gradient(#ffffff, #995400, #422900);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent; 
            font-family: sans-serif; 
            margin: 0;
            font-weight: normal;
            display: inline;
            
            padding-bottom: 10px;
        }

        span {
            font-size: 14px;
            color: #5b3500;
            margin-left: 10px;
        }

        max-width: 100%;
        border-bottom: 1px solid #5b3500;
        
    }

    p:empty {
        display: none;
    }
}