

body[name="ordering"] {
    --border-radius: 6px;

    // background-color: #f7f7f7; 
    background: linear-gradient(
        180deg,
        #ffffff,
        #fdfdfd,
        #fbfbfb,
        #f9f9f9,
        #f6f6f6,
        #f4f4f4,
        #f2f2f2,
        #f0f0f0
    );
    color: black;
    font-family: sans-serif;
    min-height: 100vh;

    overflow-x: hidden;
    overflow-y: auto;
    justify-content: space-between;

    * {
        box-sizing: border-box;
    }

    .frame {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: row;
        width: 100%;
        max-width: 1024px;

        min-height: calc(100vh - var(--footer-height));

        @media screen and (max-width: 800px) {
            margin-top: 30px;
            // justify-content: flex-start;
        }
    }

    .vertical-spacer {
        height: 380px;
        width: 1px;
        background: #d5d5d5;
        margin: 5%;
    }

    [hidden] {
        display: none;
    }

    .box {
        padding: 30px;

        @media screen and (max-width: 800px) {
            // width: calc(100% - 60px);
            
        }
    }
}
