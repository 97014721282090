discount-code {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    p {
        font-weight: bold;
    }

    button {
        cursor: pointer;
        margin: 0 !important;
    }

    input[type="text"] {
        display: none;
        text-align: center;
        padding: 10px;
        border: none;
        border-radius: 50px;
        background: white;
        box-shadow: inset 1px 1px 5px 2px #ccc;
        margin: 20px;
        max-width: 100%;
        -webkit-appearance: none;
        font-size: 20px;
        width: 200px;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 12px 1px #2200ff;
        }
    }

    &[open] {
        p {
            display: none;
        }

        input[type="text"] {
            display: block;
        }
    }

    &[completed] {
        display: none;;
    }
}
