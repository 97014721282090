:root {
    --site-font: "Times New Roman", Times, serif;
    --primary: black;
    --text-color: #b3b3b3;
}

html {
    height: 100%;
}

html, body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
}

body {
    background-color: black;
    font-family: var(--site-font); 
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    * {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: var(--text-color);
    }

    
}


input {
    -webkit-appearance: none;
}