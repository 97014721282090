feature-matrix {
    > div {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 20px;
        width: 80%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .feature {
        display: flex;
        font-family: sans-serif;
        flex-direction: column;

        > div:first-of-type {
            display: flex;
            align-items: center;
            color: white;
            margin-top: 14px;
            flex: 0 1 24px;
        }

        > div {
            color: #ffffffbd;
            margin-bottom: 16px;
            padding-right: 30px;
        }

        svg {
            max-width: 45px;
            max-height: 45px;
            margin-right: 16px;

            flex: 0 0 75px;
            fill: var(--text-color);
            stroke: var(--text-color);
        }
    }
}