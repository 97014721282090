order-viewer {
    width: 100%;

    .box {
        flex-direction: column;
    }
    
    > div {
        max-width: 1200px !important;
        border-radius: var(--border-radius) !important;
        font-size: 17px;
        display: flex;
        flex-direction: row;
        color: #545454;

        .line,
        .delivery,
        .payment,
        .total {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 20px;

            .description {
                flex: 1 1 auto;
                text-align: left;
            }

            .amount {
                flex: 0 0 30px;
            }

            button {
                cursor: pointer;
                display: inline;
                background: none;
                color: #919191;
                padding: 0 !important;
                margin: 0 !important;
            }
        }

        .total {
            color: black;
            margin-top: 30px;
            margin-bottom: 0;
            font-weight: bold;
        }

        .delivery {
            flex-direction: column;
            // font-size: 16px;
            margin-top: 30px;
            margin-bottom: 0;

            

            a {
                display: block;
                padding: 10px 0;
                color:  #4285f4;
                text-decoration: underline;
            }
        }

        .payment {
            margin-top: 30px;
        }

        span {
            font-size: 15px;
        }
    }
}
